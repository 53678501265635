import { useState } from 'react';

import dynamic from 'next/dynamic';

import { ReactComponent as Cross } from '@/assets/icons/cross.svg';
import { ReactComponent as PlayIcon } from '@/assets/icons/play.svg';
import {
  Button,
  ButtonVariant,
  Typography,
  TypographyVariant,
} from '@/components';
import { emitEvent, EMixPanelEventsMap } from '@/services/mixpanel';

const Modal = dynamic(
  () => import('../../../../components/modal').then((module) => module.Modal),
  {
    ssr: false,
  }
);

import styles from './styles.module.scss';

export const Tutorial = () => {
  const [isOpen, setIsOpen] = useState(false);

  const onToggleModal = () => setIsOpen((prev) => !prev);

  return (
    <>
      <Button
        variant={ButtonVariant.Link}
        className={styles.tutorial}
        onClick={() => {
          emitEvent(EMixPanelEventsMap.OPEN_TUTORIAL);
          onToggleModal();
        }}
        {...{ 'aria-label': 'watch-the-video' }}
      >
        <PlayIcon />
        <Typography variant={TypographyVariant.Body2Medium}>Demo</Typography>
      </Button>
      <Modal isOpen={isOpen} onClose={onToggleModal}>
        <div className={styles.tutorialModal}>
          <div className={styles.tutorialHeader}>
            <Typography
              as="h2"
              className={styles.title}
              variant={TypographyVariant.Heading1Bold}
            >
              Learn more about us
            </Typography>
            <Cross onClick={onToggleModal} />
          </div>
          <div className={styles.tutorialContent}>
            <iframe
              className={styles.tutorialVideo}
              allowFullScreen
              sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
              src="https://www.youtube.com/embed/eB5ImmWELXg"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            />
          </div>
        </div>
      </Modal>
    </>
  );
};
