import { FC, useEffect, useState } from 'react';

import clsx from 'clsx';

import { ReactComponent as ArrowTop } from '@/assets/icons/arrow-top.svg';
import { ReactComponent as Checkmark } from '@/assets/icons/checkmark.svg';
import { useOutsideClick } from '@/hooks';

import styles from './styles.module.scss';
import { IOption, ISelect } from './types';
import { Typography, TypographyVariant } from '../typography';

export const Select: FC<ISelect> = ({
  placeHolder = '',
  options,
  className,
  onChange,
  selected,
  defaultSelected = null,
  disabled = false,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(
    selected || defaultSelected
  );
  const ref = useOutsideClick(() => {
    setOpen(false);
  });

  useEffect(() => {
    if (selected) setSelectedValue(selected);
  }, [selected]);

  const toggleSelect = () => !disabled && setOpen((prev) => !prev);

  const handleItemClick = (option: IOption) => {
    if (option.disabled) {
      return;
    }

    setSelectedValue(option);
    onChange(option);
  };

  return (
    <div
      ref={ref}
      className={clsx(styles.select, className, disabled && styles.disabled)}
      onClick={toggleSelect}
    >
      <div className={clsx(styles.input, isOpen && styles.opened)}>
        <Typography className={styles.text}>
          {!selectedValue ? placeHolder : selectedValue.label}
        </Typography>
        <ArrowTop />
      </div>
      {isOpen && (
        <div className={styles.container}>
          {options.map((option) => {
            const isSelected = selectedValue?.value === option.value;

            return (
              <div
                onClick={() => handleItemClick(option)}
                className={clsx(
                  styles.option,
                  isSelected && styles.selectedOption,
                  option.disabled && styles.disabled
                )}
                key={option.value}
                title={option?.message}
              >
                <Typography
                  className={styles.label}
                  variant={
                    isSelected
                      ? TypographyVariant.Body2Medium
                      : TypographyVariant.Body1Regular
                  }
                >
                  {option.label}
                </Typography>
                {isSelected && <Checkmark />}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
