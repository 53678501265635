import { FC } from 'react';

import Image from 'next/image';

import { Typography, TypographyVariant } from '@/components';

import styles from './styles.module.scss';

interface IEmptyState {
  text: string;
}

export const EmptyTableState: FC<IEmptyState> = ({ text }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.imgWrapper}>
          <Image
            priority
            quality={100}
            src="/images/empty-state.svg"
            alt="empty-state-image"
            fill
          />
        </div>
        <Typography
          className={styles.text}
          variant={TypographyVariant.Body2Regular}
        >
          {text}
        </Typography>
      </div>
    </div>
  );
};
