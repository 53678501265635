import { FC } from 'react';

import { NavBar } from '@/pages/admin/components';

import styles from './styles.module.scss';

interface ILayout {
  children: JSX.Element;
}

export const Admin: FC<ILayout> = ({ children }) => {
  return (
    <section className={styles.layout}>
      <NavBar />
      <main className={styles.content}>{children}</main>
    </section>
  );
};
