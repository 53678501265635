import { ReactComponent as OperatingSystems } from '@/assets/icons/admin/operating-systems.svg';
import { ReactComponent as Products } from '@/assets/icons/admin/products.svg';
import { ReactComponent as ServiceCategories } from '@/assets/icons/admin/service-categories.svg';
import { ReactComponent as Versions } from '@/assets/icons/admin/versions.svg';

export const ITEMS = [
  {
    href: '/admin',
    icon: ServiceCategories,
    value: 'admin',
  },
  {
    href: '/admin/operating',
    icon: OperatingSystems,
    value: 'operating',
  },
  {
    href: '/admin/products',
    icon: Products,
    slug: '/products',
    value: 'products',
  },
  {
    href: '/admin/blog',
    icon: Versions,
    value: 'blog',
  },
];
