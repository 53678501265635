import { ReactNode } from 'react';

import { Button, Divider, Typography, TypographyVariant } from '@/components';

import styles from './styles.module.scss';

export const Heading = ({
  title,
  filter,
  onAdd,
  onBatchAdd,
}: {
  title: string;
  filter?: ReactNode;
  onAdd?: () => void;
  onBatchAdd?: () => void;
}) => {
  return (
    <div className={styles.stickyHeading}>
      <div className={styles.heading}>
        <Typography
          as="h2"
          variant={TypographyVariant.Heading3Bold}
          className={styles.title}
        >
          {title}
        </Typography>
        <div className={styles.divider} />
        <div className={styles.group}>
          {filter && filter}
          {onBatchAdd && (
            <Button onClick={onBatchAdd}>Add Multiple Versions</Button>
          )}
          {onAdd && <Button onClick={onAdd}>Add new</Button>}
        </div>
      </div>
      <Divider className={styles.divider} />
    </div>
  );
};
