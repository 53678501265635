import { FC, Fragment, useState } from 'react';

import { useMutation, useQuery } from '@tanstack/react-query';
import clsx from 'clsx';
import Image from 'next/image';
import { useSession } from 'next-auth/react';

import { ReactComponent as ArrowDownIcon } from '@/assets/icons/arrow-down.svg';
import { ReactComponent as Checkmark } from '@/assets/icons/checkmark.svg';
import { Typography, TypographyVariant } from '@/components';
import { useIsMobile } from '@/hooks';
import api from '@/services/api';

import { OnBoardingModal } from './modal';
import styles from './styles.module.scss';

const fetchSteps = async (token?: string) => {
  const resp = await fetch(`/ex-api/UserSettings/installation/flow/status`, {
    headers: {
      Authorization: `Bearer  ${token}`,
    },
  });

  return await resp.json();
};
export const OnboardingStep: FC<{ className?: string }> = ({ className }) => {
  const { data: session } = useSession();
  const isMobile = useIsMobile();
  const token = session?.user.accessToken;
  const [isOpen, setIsOpen] = useState(!isMobile);
  const [isModalOpen, setIsModalOpen] = useState(true);

  const { data: steps } = useQuery<number>({
    enabled: !!token,
    keepPreviousData: true,
    queryFn: () => fetchSteps(token),
    queryKey: ['steps', token],
  });

  const setIsSee = useMutation({
    mutationFn: () => {
      return api.put(
        `/ex-api/UserSettings/installation/flow/done`,
        {},
        {
          headers: {
            Authorization: `Bearer  ${token}`,
          },
        }
      );
    },
  });

  if (!steps || !token || steps === 5) {
    return null;
  }

  if (steps === 4) {
    return (
      <OnBoardingModal
        isOpen={isModalOpen}
        onClose={() => {
          setIsSee.mutate();
          setIsModalOpen(false);
        }}
      />
    );
  }

  const data = [
    { id: 1, isCompleated: steps >= 2, title: 'Generate script' },
    { id: 2, isCompleated: steps >= 3, title: 'Pair Host' },
    { id: 3, isCompleated: steps === 4, title: 'Install Service' },
  ];
  const isCompleatedLine = data.filter((item) => item.isCompleated).length;

  const getTitle = () => {
    switch (steps) {
      case 1:
        return 'Generate Script';
      case 2:
        return 'Pair your Host';
      case 3:
        return 'Install a Service';
    }
  };

  const getDescription = () => {
    switch (steps) {
      case 1:
        return 'In oroder to deploy services, firstly you will need to connect Exitzero to your Linux hosts. <br/><br/> To do so, name your host (optional) and tap "Generate" to receive a script for pairing.';
      case 2:
        return 'You are one step closer! To pair your host machine with Exitzero, copy the script and run it in the Host’s terminal.';
      case 3:
        return 'Congrats, the connection is set up! Go to the "Store", navigate and choose your preferred service, configure the parameters and tap “Install”.';
      default:
        return '';
    }
  };

  const getSrc = () => {
    switch (steps) {
      case 1:
        return '/images/step-1.gif?v=1';
      case 2:
        return '/images/step-2.gif?v=1';
      case 3:
        return '/images/step-3.gif?v=1';

      default:
        return '';
    }
  };

  return (
    <section
      className={clsx(
        styles.onboardingStep,
        isOpen && styles.onboardingAlign,
        className
      )}
    >
      <div
        className={clsx(
          styles.cardWrapper,
          !isOpen && styles.cardWrapperClosed
        )}
      >
        {isOpen && (
          <div className={styles.gifWrapper}>
            <Image
              quality={100}
              priority
              src={getSrc()}
              alt="pairing-instruction"
              fill
              sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
            />
          </div>
        )}
        <div className={styles.descWrapper}>
          <div className={styles.circleWrapper}>
            {data.map(({ id, title, isCompleated }, index) => {
              return (
                <Fragment key={id}>
                  <div className={styles.circleWrap}>
                    <div
                      className={clsx(
                        styles.circle,
                        isCompleated && styles.compleated
                      )}
                    >
                      {isCompleated ? <Checkmark /> : index + 1}
                    </div>
                    <Typography>{title}</Typography>
                  </div>
                  {index < data.length - 1 && (
                    <div
                      className={clsx(
                        styles.line,
                        isCompleatedLine === 2 &&
                          index === 0 &&
                          styles.lineCompleated
                      )}
                    />
                  )}
                </Fragment>
              );
            })}
          </div>
          {isOpen && (
            <div className={styles.expandedContent}>
              <Typography variant={TypographyVariant.Heading1Bold}>
                {getTitle()}
              </Typography>
              <p
                className={styles.desc}
                dangerouslySetInnerHTML={{ __html: getDescription() }}
              />
            </div>
          )}
        </div>
      </div>
      <ArrowDownIcon
        onClick={() => setIsOpen((prev) => !prev)}
        color="rgba(255,255,255,.65)"
        style={{
          cursor: 'pointer',
          position: 'absolute',
          right: 20,
          rotate: isOpen ? '180deg' : '0deg',
          top: 20,
        }}
      />
      {/* {isOpen && (
        <div className={styles.expandedContent}>
          <Typography variant={TypographyVariant.Heading1Bold}>
            {getTitle()}
          </Typography>
          <p
            className={styles.desc}
            dangerouslySetInnerHTML={{ __html: getDescription() }}
          />
        </div>
      )} */}
    </section>
  );
};
