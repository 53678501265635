import clsx from 'clsx';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';

import { ReactComponent as Logo } from '@/assets/icons/admin/logo.svg';
import { Typography, TypographyVariant } from '@/components';

import { ITEMS } from './constants';
import styles from './styles.module.scss';

export const NavBar = () => {
  const { data } = useSession();
  const router = useRouter();

  return (
    <nav className={styles.navBar}>
      <Logo />
      <div className={styles.navItemsWrapper}>
        {ITEMS.map(({ value, href, slug, icon: IconComponent }) => {
          const active =
            (router.pathname === href && router.pathname.includes(value)) ||
            (slug && router.pathname.includes(slug));

          return (
            <Link
              key={value}
              href={href}
              className={clsx({ [styles.active]: active })}
            >
              <IconComponent />
            </Link>
          );
        })}
      </div>
      <div className={styles.avatar}>
        <Typography
          variant={TypographyVariant.Heading5Bold}
          className={styles.userName}
        >
          {data?.user?.name?.[0].toUpperCase()}
        </Typography>
      </div>
    </nav>
  );
};
