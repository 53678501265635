import { useEffect } from 'react';

import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

import { ReactComponent as BurgerMenu } from '@/assets/icons/burger-menu.svg';
import { Button, ButtonVariant } from '@/components';

import styles from './styles.module.scss';

const Drawer = dynamic(
  () => import('./drawer').then((module) => module.Drawer),
  {
    ssr: false,
  }
);

export const Header = () => {
  const router = useRouter();
  const { pathname } = router;

  useEffect(() => {
    if (pathname) {
      router.replace({
        query: { ...router.query },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const toggleDrawer = () => {
    const { drawer, ...routerQuery } = router.query;
    router.replace({
      query: {
        ...routerQuery,
        ...(!drawer ? { drawer: 'open' } : {}),
      },
    });
  };

  return (
    <>
      <header className={styles.header}>
        <Button
          onClick={toggleDrawer}
          variant={ButtonVariant.Link}
          {...{ 'aria-label': 'burger-menu' }}
        >
          <BurgerMenu />
        </Button>
      </header>
      <Drawer
        isOpen={Boolean(router.query.drawer)}
        toggleDrawer={toggleDrawer}
      />
    </>
  );
};

Header.displayName = 'Header';
