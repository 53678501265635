import { MouseEvent } from 'react';

import clsx from 'clsx';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';

import { Loader, Typography } from '@/components';

import { AUTHORIZED_MENU_ITEMS, UNAUTHORIZED_MENU_ITEMS } from './constants';
import styles from './styles.module.scss';

export const Navbar = () => {
  const { status } = useSession();
  const isAuthenticated = status === 'authenticated';
  const isLoading = status === 'loading';
  const router = useRouter();
  const MenuItems = isAuthenticated
    ? AUTHORIZED_MENU_ITEMS
    : UNAUTHORIZED_MENU_ITEMS;

  if (isLoading) {
    return <Loader />;
  }

  const handleRedirect = (e: MouseEvent<SVGSVGElement>, href: string) => {
    e.preventDefault();
    router.push(href);
  };

  return (
    <nav className={styles.navbar}>
      {MenuItems.map(
        ({
          label,
          value,
          href,
          disabled,
          rightIcon: RightIconComponent,
          rightIconHref,
        }) => {
          const active =
            router.pathname === href || router.pathname.includes(value);

          return (
            <Link
              href={href}
              key={value}
              {...{ 'aria-label': `go to exitzero ${label} page` }}
            >
              <Typography
                className={clsx(
                  styles.label,
                  disabled && styles.labelDisabled,
                  active && styles.labelActive
                )}
              >
                {label}
                {RightIconComponent && rightIconHref && (
                  <div className={styles.labelIcon}>
                    <RightIconComponent
                      onClick={(e) => handleRedirect(e, rightIconHref)}
                    />
                  </div>
                )}
              </Typography>
            </Link>
          );
        }
      )}
    </nav>
  );
};
