import { createContext } from 'react';

interface DockerContext {
  updateSavedData: number;
  setUpdateSavedData: React.Dispatch<React.SetStateAction<number>>;
}

export const UpdateSavedDataContext = createContext<DockerContext>({
  setUpdateSavedData: () => null,
  updateSavedData: 0,
});
