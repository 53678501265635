import { FC, useEffect, useState } from 'react';

import styles from './styles.module.scss';
import { Button, ButtonVariant } from '../button';

interface Options {
  label: string;
  id: number;
}
interface ToggleButtonProps {
  options: Options[];
  defaultActive?: number;
  onActiveState: (index: number) => void;
}

export const ToggleButton: FC<ToggleButtonProps> = ({
  options,
  defaultActive,
  onActiveState,
}) => {
  const [activeId, setActiveId] = useState<number | undefined>(defaultActive);

  useEffect(() => {
    setActiveId(defaultActive);
  }, [defaultActive]);

  const handleButtonClick = (id: number) => {
    if (id === activeId) {
      return null;
    } else {
      setActiveId(id);
      onActiveState(id);
    }
  };

  return (
    <div className={styles.buttonGroup}>
      {options.map((option) => (
        <Button
          className={
            option.id === activeId ? styles.activeButton : styles.linkButton
          }
          variant={
            option.id === activeId ? ButtonVariant.Primary : ButtonVariant.Link
          }
          key={option.id}
          onClick={() => handleButtonClick(option.id)}
        >
          {option.label}
        </Button>
      ))}
    </div>
  );
};
