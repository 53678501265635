import mixpanel from 'mixpanel-browser';

import { getFromStorage } from './localstorage';

const isProduction = process.env.NODE_ENV === 'production';

export enum EMixPanelEventsMap {
  // actions
  SESSION_START = 'session_start',
  SERVICE_ADDED = 'service_added',
  SERVICE_REMOVED = 'service_removed',

  HOST_ADDED = 'host_added',
  HOST_REMOVED = 'host_removed',
  HOST_RENAMED = 'host_renamed',
  GENERATE_UNPAIRED_HOST = 'generate_unpaired_host',

  // pages
  VISITED_STORE_PAGE = 'visited_store_page',
  VISITED_SINGLE_STORE_PAGE = 'visited_single_store_page',

  VISITED_HOSTS_PAGE = 'visited_hosts_page',
  VISITED_SINGLE_HOST_PAGE = 'visited_single_host_page',

  VISITED_SERVICES_PAGE = 'visited_services_page',
  VISITED_SINGLE_SERVICES_PAGE = 'visited_single_service_page',

  VISITED_ACCOUNT_PAGE = 'visited_account_page',

  // auth
  LOGIN_EVENT = 'login_event',
  REGISTER_EVENT = 'register_event',
  LOGOUT_EVENT = 'logout_event',

  GITLAB_REDIRECT = 'visited_gitlab',
  OPEN_TUTORIAL = 'open_tutorial',

  OPEN_PAIRING_INSTRUCTION = 'open_pairing_instruction',

  OPEN_FB_CHAT = 'open_fb_chat',
}

mixpanel.init(String(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN) || '', {
  debug: !isProduction,
});

export const emitEvent = (
  eventName: EMixPanelEventsMap,
  data?: Record<string, unknown>
) => {
  if (isProduction) {
    const user = getFromStorage('exitzero_user');
    const userData = user ? JSON.parse(String(user)) : null;

    if (userData) {
      mixpanel.identify(userData.email);
      mixpanel.people.set({
        $distinct_id: userData.email,
        $first_name: userData.name,
      });
    }

    mixpanel.track(eventName, { ...data, email: userData?.email });
  }
};
