import { MouseEvent, useEffect } from 'react';

import { Menu, MenuButton, MenuItem } from '@szhsin/react-menu';
import { useRouter } from 'next/router';
import { signIn, signOut, useSession } from 'next-auth/react';

import { ReactComponent as ContactIcon } from '@/assets/icons/contactus.svg';
import { ReactComponent as LogOutIcon } from '@/assets/icons/logout.svg';
import { ReactComponent as UserIcon } from '@/assets/icons/user.svg';
import {
  Button,
  ButtonVariant,
  Typography,
  TypographyVariant,
} from '@/components';
import { setToStorage } from '@/services/localstorage';
import { emitEvent, EMixPanelEventsMap } from '@/services/mixpanel';

import styles from './styles.module.scss';

export const Auth = () => {
  const router = useRouter();
  const { data: session, status } = useSession();

  useEffect(() => {
    if (session?.user) {
      const { name, email } = session?.user || {};
      emitEvent(EMixPanelEventsMap.LOGIN_EVENT);
      setToStorage('exitzero_user', JSON.stringify({ email, name }));
    }
  }, [session]);

  useEffect(() => {
    const { query } = router;

    if (query.s) {
      signIn('duende-identity-server6', {
        callbackUrl: `${process.env.NEXT_PUBLIC_HOST_URI}?isNewUser=true`,
      });
    }

    if (query.si) {
      signIn('duende-identity-server6', {
        callbackUrl: `${process.env.NEXT_PUBLIC_HOST_URI}`,
      });
    }
  }, [router]);

  const handleSignUp = () => {
    window.open(
      `${
        process.env.NEXT_PUBLIC_AUTHORITY as string
      }/Account/Register?returnUrl=${
        process.env.NEXT_PUBLIC_HOST_URI as string
      }`,
      '_self'
    );
  };

  const handleSignIn = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    signIn('duende-identity-server6', {
      callbackUrl: process.env.NEXT_PUBLIC_HOST_URI,
    });
  };

  const handleSignOut = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    signOut();
    emitEvent(EMixPanelEventsMap.LOGOUT_EVENT, {
      userEmail: session?.user?.email,
    });
  };

  if (status === 'loading') {
    return null;
  }

  if (session) {
    return (
      <Menu
        direction="right"
        position="auto"
        align="end"
        menuClassName={styles.navMenu}
        menuButton={
          <MenuButton className={styles.userWrapper}>
            <div className={styles.avatar}>
              <Typography
                variant={TypographyVariant.Heading5Bold}
                className={styles.userName}
              >
                {session.user?.name?.[0].toUpperCase()}
              </Typography>
            </div>
            <Typography className={styles.userName}>
              {session.user?.email}
            </Typography>
          </MenuButton>
        }
      >
        <MenuItem className={styles.menuItem}>
          <Button
            variant={ButtonVariant.Link}
            onClick={() => {
              emitEvent(EMixPanelEventsMap.VISITED_ACCOUNT_PAGE);
              window.open(
                `${process.env.NEXT_PUBLIC_AUTHORITY as string}/Manage`
              );
            }}
            {...{ 'aria-label': 'view-profile' }}
          >
            <UserIcon />
            <Typography variant={TypographyVariant.Body1Regular}>
              View Profile
            </Typography>
          </Button>
        </MenuItem>
        <hr className={styles.divider} />
        <MenuItem className={styles.menuItem}>
          <Button
            variant={ButtonVariant.Link}
            onClick={() => {
              router.push('/contact-us');
            }}
            {...{ 'aria-label': 'contact-us' }}
          >
            <ContactIcon />
            <Typography variant={TypographyVariant.Body1Regular}>
              Contact Us
            </Typography>
          </Button>
        </MenuItem>
        <hr className={styles.divider} />
        <MenuItem className={styles.menuItem}>
          <Button
            variant={ButtonVariant.Link}
            onClick={handleSignOut}
            {...{ 'aria-label': 'log-out' }}
          >
            <LogOutIcon />
            <Typography variant={TypographyVariant.Body1Regular}>
              Log Out
            </Typography>
          </Button>
        </MenuItem>
      </Menu>
    );
  }

  return (
    <div className={styles.auth}>
      <Button onClick={handleSignUp} {...{ 'aria-label': 'registration' }}>
        Try for free
      </Button>
      <Button
        variant={ButtonVariant.Secondary}
        onClick={handleSignIn}
        {...{ 'aria-label': 'sign-in' }}
      >
        Sign In
      </Button>
    </div>
  );
};
