import { format, parseISO } from 'date-fns';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const debounce = <F extends (...args: any[]) => void>(
  func: F,
  delay: number
) => {
  let timeoutId: NodeJS.Timeout;

  return (...args: Parameters<F>) => {
    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

export function formatDate(date: string): string {
  return format(parseISO(date), 'dd.MM.yyyy');
}

export const formatMbToGb = (mb: number): string => {
  if (mb < 1024) {
    return `${mb} MB`;
  }

  return `${+(mb / 1024).toFixed(1)} GB`;
};

export const checkIsTrue = (
  data: Record<string, boolean> | undefined
): boolean => {
  if (!data) {
    return false;
  }

  let hasTrueValue = false;

  for (const key in data) {
    if (data[key] === true) {
      hasTrueValue = true;
      break; // If any true value is found, exit the loop early
    }
  }

  return hasTrueValue;
};

export function getFirstTrueKey(data: Record<string, boolean>) {
  for (const key in data) {
    if (data[key] === true) {
      return key;
    }
  }

  return null;
}

export function isTextLineClamped(element: HTMLParagraphElement) {
  const computedStyle = window.getComputedStyle(element);
  const lineHeight = parseFloat(computedStyle.lineHeight);
  const maxLines = parseInt(
    computedStyle.webkitLineClamp ||
      computedStyle.getPropertyValue('-webkit-line-clamp') ||
      '0'
  );
  const maxHeight = maxLines * lineHeight;

  return element.scrollHeight > maxHeight;
}
