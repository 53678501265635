import { useCallback, useEffect } from 'react';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';
import { Store } from 'react-notifications-component';

import api from '@/services/api';
import { emitEvent, EMixPanelEventsMap } from '@/services/mixpanel';

interface IPendingHostNotification {
  hostName: string;
  hostStatus: string;
  showNotification: boolean;
  isPaired: boolean;
  hostId: string;
}

const fetchNotifications = async (token: string) => {
  const resp = await fetch(`/ex-api/Host/notifications`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return await resp.json();
};

export const HostNotifications = () => {
  const { data } = useSession();
  const token = data?.user.accessToken;
  const queryClient = useQueryClient();
  const { data: notifications } = useQuery<IPendingHostNotification[]>({
    enabled: !!token,
    queryFn: () => fetchNotifications(String(token)),
    queryKey: ['host-notifications', token],
    refetchInterval: 15000,
  });

  const setIsSee = useMutation({
    mutationFn: ({ id }: { id: string }) => {
      return api.put(
        `/ex-api/Host/notificated/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer  ${token}`,
          },
        }
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries();
    },
  });

  const handleRemove = useCallback(
    (id: string) => {
      setIsSee.mutate({ id });
    },
    [setIsSee]
  );

  useEffect(() => {
    notifications?.map((item) => {
      if (item.showNotification) {
        Store.addNotification({
          container: 'top-right',
          dismiss: {
            duration: 2500,
            onScreen: true,
            pauseOnHover: true,
          },
          id: item.hostId,
          insert: 'top',
          message: `successfully paired`,
          onRemoval: () => handleRemove(item.hostId),
          title: `Host ${item.hostName}`,
          type: 'success',
        });
        emitEvent(EMixPanelEventsMap.HOST_ADDED, {
          hostId: item.hostId,
          hostName: item.hostName,
        });
      }
    });
  }, [notifications, handleRemove]);

  return null;
};
