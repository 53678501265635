import { MouseEvent, useState } from 'react';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';

import { ReactComponent as ArrowDownIcon } from '@/assets/icons/arrow-down.svg';
import { ReactComponent as CloseIcon } from '@/assets/icons/close.svg';
import { ReactComponent as NotificationWarningIcon } from '@/assets/icons/notification-warning.svg';
import { ReactComponent as ProgressIcon } from '@/assets/icons/progress.svg';
import {
  Button,
  ButtonVariant,
  Typography,
  TypographyVariant,
} from '@/components';
import api from '@/services/api';

import styles from './styles.module.scss';

interface IServicesNotification {
  hostName: string;
  serviceName: string;
  isInstallationCompleded: boolean;
  isNotificated: boolean;
  hostProductId: string;
  serviceStatus: string;
  serviceId: string;
}
export enum NotificationsVariant {
  Loading = 'loading',
  Failed = 'failed',
  Completed = 'completed',
}

const fetchNotifications = async (token: string) => {
  const resp = await fetch(`/ex-api/HostProduct/pending`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return await resp.json();
};

export const ServicesNotifications = () => {
  const router = useRouter();
  const [isOpen, setIsOpen] = useState(false);
  const { data } = useSession();
  const token = data?.user.accessToken;
  const queryClient = useQueryClient();
  const { data: notifications } = useQuery<IServicesNotification[]>({
    enabled: !!token,
    queryFn: () => fetchNotifications(String(token)),
    queryKey: ['service-notifications', token],
    refetchInterval: 15000,
  });

  const setIsSee = useMutation({
    mutationFn: ({ id }: { id: string }) => {
      return api.put(
        `/ex-api/HostProduct/install/notificated/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer  ${token}`,
          },
        }
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries();
    },
  });

  if (!notifications?.length) {
    return null;
  }

  const toggleOpen = () => setIsOpen((prev) => !prev);

  const getVariant = (
    serviceStatus: string,
    isInstallationCompleded: boolean
  ) => {
    if (!isInstallationCompleded) {
      return NotificationsVariant.Loading;
    }

    if (serviceStatus.toLowerCase() === 'failed') {
      return NotificationsVariant.Failed;
    }

    return NotificationsVariant.Completed;
  };

  const getText = (variant: NotificationsVariant) => {
    switch (variant) {
      case NotificationsVariant.Loading:
        return 'in process...';
      case NotificationsVariant.Failed:
        return 'failed to install';

      default:
        return 'installed';
    }
  };

  const renderItem = ({
    hostName,
    serviceName,
    serviceStatus,
    isInstallationCompleded,
    hostProductId,
  }: {
    hostName: string;
    serviceName: string;
    serviceStatus: string;
    isInstallationCompleded: boolean;
    hostProductId: string;
  }) => {
    const variant = getVariant(serviceStatus, isInstallationCompleded);

    const handleClose = (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      setIsSee.mutate({ id: hostProductId });
    };

    const handleRedirect = (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      router.push(`/my-services/${hostProductId}`);
    };

    return (
      <div
        key={hostProductId}
        className={clsx(styles.item, styles[variant])}
        onClick={(e) => handleRedirect(e)}
      >
        <div className={styles.textsWrapper}>
          <Typography variant={TypographyVariant.Body1Medium}>
            {hostName}
          </Typography>
          <Typography>
            {serviceName} {getText(variant)}
          </Typography>
        </div>
        {variant === NotificationsVariant.Loading ? (
          <ProgressIcon className={styles.progress} />
        ) : (
          <Button
            variant={ButtonVariant.Link}
            className={styles.button}
            onClick={handleClose}
          >
            <CloseIcon />
          </Button>
        )}
      </div>
    );
  };

  return (
    <div className={styles.servicesNotifications}>
      <div className={styles.services} onClick={toggleOpen}>
        <div className={styles.textIcon}>
          <NotificationWarningIcon />
          <Typography>
            {`${notifications?.length} installation${
              notifications?.length > 1 ? 's' : ''
            } `}
          </Typography>
        </div>
        <ArrowDownIcon
          color="rgba(255,255,255,.65)"
          style={{
            rotate: isOpen ? '180deg' : '0deg',
          }}
        />
      </div>
      {isOpen && (
        <div className={styles.container}>
          {notifications.map(
            ({
              hostName,
              serviceName,
              serviceStatus,
              isInstallationCompleded,
              isNotificated,
              hostProductId,
            }) =>
              !isNotificated &&
              renderItem({
                hostName,
                hostProductId,
                isInstallationCompleded,
                serviceName,
                serviceStatus,
              })
          )}
        </div>
      )}
    </div>
  );
};
