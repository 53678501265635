import Link from 'next/link';
import { useRouter } from 'next/router';

import { ReactComponent as Cross } from '@/assets/icons/close.svg';
import { ReactComponent as ExitZeroLogo } from '@/assets/images/logo.svg';
import { Button, ButtonVariant } from '@/components';
import { useIsMobile } from '@/hooks';

import styles from './styles.module.scss';

export const Logo = () => {
  const router = useRouter();
  const isMobile = useIsMobile();

  const handleCloseDrawer = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { drawer, ...routerQuery } = router.query;
    router.replace({
      query: {
        ...routerQuery,
      },
    });
  };

  return (
    <div className={styles.logo}>
      <Link href="/" {...{ 'aria-label': 'go to exitzero home page' }}>
        <ExitZeroLogo />
      </Link>
      {isMobile && (
        <Button variant={ButtonVariant.Link} onClick={handleCloseDrawer}>
          <Cross />
        </Button>
      )}
    </div>
  );
};
