import { FC, useState } from 'react';

import clsx from 'clsx';
import dynamic from 'next/dynamic';

import {
  Button,
  ButtonVariant,
  Typography,
  TypographyVariant,
} from '@/components';

import styles from './styles.module.scss';
import { IConfirmationModal } from './types';

const Modal = dynamic(() => import('../modal').then((module) => module.Modal), {
  ssr: false,
});

export const ConfirmationModal: FC<IConfirmationModal> = ({
  isOpen,
  name,
  onConfirm,
  onClose,
}) => {
  const [inputValue, setInputValue] = useState('');

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className={styles.confirmationModal}>
        <Typography
          as="h2"
          className={styles.title}
          variant={TypographyVariant.Heading1Bold}
        >
          {`Are you sure you want to delete ${name}?`}
        </Typography>
        <div className={styles.confirmationContent}>
          <Typography
            className={styles.title}
            variant={TypographyVariant.Body1Medium}
          >
            Type “Delete” to continue
          </Typography>
          <input
            name="confirmation"
            value={inputValue}
            onChange={(e) => {
              e.stopPropagation();
              setInputValue(e.target.value);
            }}
            className={styles.input}
          />
        </div>
        <div className={styles.buttonGroup}>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              onClose();
              setInputValue('');
            }}
            variant={ButtonVariant.Secondary}
            {...{ 'aria-label': 'cancel' }}
          >
            Cancel
          </Button>
          <Button
            disabled={inputValue !== 'Delete'}
            className={clsx(
              inputValue === 'Delete' && styles.confirmationButton
            )}
            onClick={() => {
              setInputValue('');
              onConfirm();
            }}
            variant={ButtonVariant.Secondary}
            {...{ 'aria-label': 'delete' }}
          >
            Delete
          </Button>
        </div>
      </div>
    </Modal>
  );
};
