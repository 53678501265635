import { FC } from 'react';

import dynamic from 'next/dynamic';

const Modal = dynamic(
  () => import('../../components/modal').then((module) => module.Modal),
  {
    ssr: false,
  }
);
import { ReactComponent as Cross } from '@/assets/icons/cross.svg';
import { ReactComponent as Star } from '@/assets/icons/star.svg';
import { Typography, TypographyVariant } from '@/components';

import styles from './styles.module.scss';

export const OnBoardingModal: FC<{ isOpen: boolean; onClose: () => void }> = ({
  isOpen,
  onClose,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className={styles.modalHeader}>
        <Cross onClick={onClose} />
      </div>
      <div className={styles.modal}>
        <Star />
        <Typography variant={TypographyVariant.Heading2Bold}>
          You’re all done!
        </Typography>
        <Typography className={styles.secondary}>
          You have successfully installed your first service!
          <br /> Now you may continue exploring Exitzero.
        </Typography>
      </div>
    </Modal>
  );
};
