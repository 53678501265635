import { ReactComponent as PlusIcon } from '@/assets/icons/plus.svg';

import { INavBar } from './types';

export const UNAUTHORIZED_MENU_ITEMS: INavBar[] = [
  {
    href: '/store',
    label: 'Store',
    value: 'store',
  },
  // {
  //   href: '/blog',
  //   label: 'Blog',
  //   value: 'blog',
  // },
  {
    href: '/about-us',
    label: 'About us',
    value: 'aboutUs',
  },
  {
    href: '/contact-us',
    label: 'Contact Us',
    value: 'contactUs',
  },
];

export const AUTHORIZED_MENU_ITEMS: INavBar[] = [
  {
    href: '/store',
    label: 'Store',
    value: 'store',
  },
  {
    href: '/dashboard',
    label: 'Dashboard',
    value: 'dashboard',
  },
  {
    href: '/paired-hosts',
    label: 'Hosts',
    rightIcon: PlusIcon,
    rightIconHref: '/unpaired-hosts',
    value: 'hosts',
  },
  {
    href: '/my-services',
    label: 'My Services',
    value: 'myServices',
  },
];
