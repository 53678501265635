import { Auth, Logo, Navbar, Search, Tutorial } from './features';
import styles from './styles.module.scss';

const LeftSide = () => {
  return (
    <aside className={styles.aside}>
      <Logo />
      <Search />
      <Navbar />
      <Tutorial />
      <div className={styles.bottomWrapper}>
        <Auth />
      </div>
    </aside>
  );
};

export default LeftSide;
