import { ReactNode } from 'react';

import clsx from 'clsx';

import styles from './styles.module.scss';
import { ITable } from './types';
import { Typography, TypographyVariant } from '../typography';

export const Table = <T,>({
  data,
  columns,
  rowClassName,
  cellClassName,
  className,
  onRowClick,
}: ITable<T>) => {
  return (
    <div className={clsx(styles.tableWrapper, className)}>
      <table className={styles.table}>
        <thead>
          <tr>
            {columns.map((column) => (
              <th
                key={column.key}
                style={{ width: column.width }}
                className={clsx(styles.tableHeaderCell, cellClassName)}
              >
                <Typography variant={TypographyVariant.Heading5Bold}>
                  {column.title}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item, itemIndex) => (
            <tr
              className={clsx(styles.tableRowItem, rowClassName, {
                [styles.cursor]: onRowClick,
              })}
              key={`table-body-${itemIndex}`}
              onClick={onRowClick ? () => onRowClick(item) : undefined}
            >
              {columns.map((column) => (
                <td
                  key={column.key}
                  style={{ textAlign: column?.align }}
                  className={styles.tableCell}
                >
                  {column.render ? (
                    column.render(item)
                  ) : (
                    <Typography>
                      {(item?.[column.key as keyof T] as ReactNode) || '-'}
                    </Typography>
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

{
  /* <div className={styles.table}>
<div className={styles.tHead}>
  {columns.map(({ title, id }) => (
    <Typography
      variant={TypographyVariant.Heading5Bold}
      className={clsx(styles.item, styles[id])}
      key={id}
    >
      {title}
    </Typography>
  ))}
</div>
<div className={styles.tBody}>
  {rows.map(({ id, ...rowItem }) => (
    <div key={id} className={clsx(styles.row, rowClassName)}>
      {Object.entries(rowItem).map(([key, value]) => (
        <div key={key} className={clsx(styles.item, styles[key])}>
          {value}
        </div>
      ))}
    </div>
  ))}
</div>
</div> */
}
