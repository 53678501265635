import { FC } from 'react';

import { Menu, MenuButton, MenuItem } from '@szhsin/react-menu';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import { Store } from 'react-notifications-component';

import { ReactComponent as Copy } from '@/assets/icons/copy.svg';
import { ReactComponent as DotsIcon } from '@/assets/icons/dots.svg';
import { ReactComponent as Edit } from '@/assets/icons/edit.svg';
import { ReactComponent as ProgressIcon } from '@/assets/icons/progress.svg';
import { Button, ButtonVariant, Status, Typography } from '@/components';
import api from '@/services/api';
import { formatDate, formatMbToGb } from '@/services/helpers';
import { emitEvent, EMixPanelEventsMap } from '@/services/mixpanel';

import styles from './styles.module.scss';
import { Data } from './types';
import { RemoveActionMenu } from '../remove-action-menu';
import { RenameHost } from '../rename-host';

const ActionsMenu: FC<{ id: string; name: string }> = ({ id, name }) => {
  const router = useRouter();
  const { data } = useSession();
  const queryClient = useQueryClient();
  const removeHost = useMutation({
    mutationFn: () => {
      return api.delete(`/ex-api/Host/${id}`, {
        headers: {
          Authorization: `Bearer  ${data?.user.accessToken}`,
        },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries();
    },
  });

  const handleRemove = () => {
    emitEvent(EMixPanelEventsMap.HOST_REMOVED, { id, name });
    removeHost.mutate();
  };

  return (
    <>
      <Menu
        direction="left"
        position="auto"
        align="end"
        menuClassName={styles.actionMenu}
        menuButton={
          <MenuButton
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            className={styles.menuButton}
            {...{ 'aria-label': 'additonal-menu-button' }}
          >
            <DotsIcon />
          </MenuButton>
        }
      >
        <MenuItem className={styles.menuItem}>
          <Button
            className={styles.rename}
            variant={ButtonVariant.Link}
            {...{ 'aria-label': 'rename-host' }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              router.replace({ query: { ...router.query, renameHost: id } });
            }}
          >
            <Edit />
            Rename Host
          </Button>
        </MenuItem>
        <MenuItem className={styles.menuItem}>
          <RemoveActionMenu
            id={id}
            name={name}
            displayName="Delete host"
            onRemove={handleRemove}
          />
        </MenuItem>
      </Menu>
    </>
  );
};

const UpdateButton = ({
  isUpdateInProgress,
  hostId,
}: {
  isUpdateInProgress: boolean;
  hostId: string;
}) => {
  const queryClient = useQueryClient();

  const { data: session } = useSession();
  const updateHost = useMutation({
    mutationFn: () => {
      return api.put(
        `/ex-api/Host/Cli?hostId=${hostId}`,
        {},
        {
          headers: {
            Authorization: `Bearer  ${session?.user.accessToken}`,
          },
        }
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['all-paired-hosts'] });
    },
  });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleUpdateHost = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    updateHost.mutate();
  };

  return (
    <div className={styles.buttonWrapper}>
      {isUpdateInProgress ? (
        <Button
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          className={styles.loaderButton}
        >
          <ProgressIcon className={styles.progress} />
        </Button>
      ) : (
        <Button onClick={handleUpdateHost}>Update</Button>
      )}
    </div>
  );
};

export const HOSTS_PAIRED_COLUMNS = [
  {
    key: 'name',
    render: (item: Data) => <RenameHost name={item.name} id={item.hostId} />,
    title: 'Host Name',
    width: 200,
  },
  {
    key: 'isUpdate',
    render: (item: Data) => {
      return item.isHostUpToDate ? (
        <Typography>Up to Date</Typography>
      ) : (
        <UpdateButton
          hostId={item.hostId}
          isUpdateInProgress={item.isUpdateInProgress}
        />
      );
    },
    title: 'Version',
  },
  {
    key: 'operatingSystem',
    render: (item: Data) => <Typography>{item.operatingSystem}</Typography>,
    title: 'OS',
  },
  {
    key: 'ram',
    render: (item: Data) => (
      <Typography>{formatMbToGb(Number(item.ram))}</Typography>
    ),
    title: 'RAM',
  },
  {
    key: 'cpu',
    title: 'CPU information',
  },
  {
    key: 'ipAddress',
    title: 'IP address',
  },
  {
    key: 'active',
    render: (item: Data) => <Status status={item.status} />,
    title: 'Status',
  },
  {
    align: 'end' as const,
    key: 'actions',
    render: (item: Data) => <ActionsMenu id={item.hostId} name={item.name} />,
    title: '',
    width: 160,
  },
];

export const HOSTS_UN_PAIRED_COLUMNS = [
  {
    key: 'name',
    render: (item: Data) => <RenameHost name={item.name} id={item.hostId} />,
    title: 'Host Name',
    width: 200,
  },
  {
    key: 'script',
    render: (item: Data) => {
      const handleCopy = async () => {
        await navigator.clipboard.writeText(item.script).then(() => {
          Store.addNotification({
            container: 'top-right',
            dismiss: {
              duration: 2500,
              onScreen: true,
              pauseOnHover: true,
            },
            insert: 'top',
            message: 'text copied to clipboard',
            title: 'Success',
            type: 'success',
          });
        });
      };

      return (
        <div className={styles.scirptWrapper}>
          <Typography className={styles.scriptTitle}>{item.script}</Typography>
          <Button
            onClick={handleCopy}
            variant={ButtonVariant.Secondary}
            {...{ 'aria-label': 'copy' }}
          >
            <Copy className={styles.copyIcon} />
            Copy
          </Button>
        </div>
      );
    },
    title: 'Script',
    width: 579,
  },
  {
    key: 'status',
    render: (item: Data) => <Status status={item.status} />,
    title: 'Status',
  },
  {
    key: 'createDate',
    render: (item: Data) => (
      <Typography suppressHydrationWarning>
        {formatDate(item.createDate)}
      </Typography>
    ),
    title: 'Date',
  },
  {
    align: 'end' as const,
    key: 'actions',
    render: (item: Data) => <ActionsMenu id={item.hostId} name={item.name} />,
    title: '',
    width: 160,
  },
];
