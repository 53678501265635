import { FC } from 'react';

import styles from './styles.module.scss';
import { IProgressBar } from './types';

const Circle: FC<IProgressBar> = ({ colour, percentage }) => {
  const r = 46.5;
  const circ = 2 * Math.PI * r;
  const strokePct = ((100 - percentage) * circ) / 100;

  return (
    <circle
      className={styles.circle}
      r={r}
      cx={0}
      cy={0}
      fill="transparent"
      stroke={strokePct !== circ ? colour : ''}
      strokeWidth="7px"
      strokeDasharray={circ}
      strokeDashoffset={percentage ? strokePct : 0}
      strokeLinecap="round"
    ></circle>
  );
};

export const ProgressBar: FC<IProgressBar> = ({ percentage, colour }) => {
  return (
    <svg width={90} height={90} viewBox="-50 -50 100 100">
      <g>
        <Circle colour={`var(--background--light)`} percentage={100} />
        <Circle colour={colour} percentage={percentage} />
      </g>
    </svg>
  );
};
