export const DEFAULT_ELEMENT = 'span';

export enum TypographyVariant {
  Body1Small = 'body1Small',
  Body1Regular = 'body1Regular',
  Body2Regular = 'body2Regular',
  Body1Medium = 'body1Medium',
  Body2Medium = 'body2Medium',
  Heading2Regular = 'heading2Regular',
  Heading1Bold = 'heading1Bold',
  Heading2Bold = 'heading2Bold',
  Heading3Bold = 'heading3Bold',
  Heading5Bold = 'heading5Bold',
  Heading6Bold = 'heading6Bold',
  Heading2BoldHelvetica = 'heading2BoldHelvetica',
}
