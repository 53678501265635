import { ReactComponent as GitLabIcon } from '@/assets/icons/gitlab.svg';
import { Button, ButtonVariant, Divider, Typography } from '@/components';
import { emitEvent, EMixPanelEventsMap } from '@/services/mixpanel';

import styles from './styles.module.scss';

export const Gitlab = () => {
  const handleRedirectGitLab = () => {
    emitEvent(EMixPanelEventsMap.GITLAB_REDIRECT);
    window.open('https://gitlab.com/exit-zero/installation-scripts');
  };

  return (
    <div className={styles.gitlabWrapper}>
      <Divider />
      <Button
        onClick={handleRedirectGitLab}
        variant={ButtonVariant.Link}
        {...{ 'aria-label': 'gitlab' }}
      >
        <div className={styles.gitlab}>
          <GitLabIcon />
        </div>
        <Typography>Our GitLab</Typography>
      </Button>
    </div>
  );
};
