import { createContext } from 'react';

import { Data } from '@/pages/store/features/services/types';

interface DockerCheckboxesContext {
  checkedItems?: { [key: string]: Data };
  setCheckedItems: React.Dispatch<
    React.SetStateAction<{ [key: string]: Data } | undefined>
  >;
}

export const DockerCheckboxesContext = createContext<DockerCheckboxesContext>({
  checkedItems: undefined,
  setCheckedItems: () => null,
});
