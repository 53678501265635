import { FC } from 'react';

import Image from 'next/image';

import { Typography, TypographyVariant } from '@/components';

import styles from './styles.module.scss';

interface IEmptyState {
  text: string;
  description?: string;
}

export const EmptyState: FC<IEmptyState> = ({ text, description }) => {
  return (
    <div className={styles.emptyState}>
      <div className={styles.content}>
        <div className={styles.imgWrapper}>
          <Image
            priority
            quality={100}
            src="/images/empty-state.svg"
            alt="empty-state-image"
            fill
          />
        </div>
        <div className={styles.info}>
          <Typography
            className={styles.title}
            as="h2"
            variant={TypographyVariant.Heading3Bold}
          >
            {text}
          </Typography>
          {description && (
            <Typography variant={TypographyVariant.Body2Regular}>
              {description}
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
};
