/* eslint-disable no-console */
import { useContext, useEffect } from 'react';

import { IsDockerContext } from '@/services/docker-context';

const SESSION_STORAGE_KEY = 'isDocker';

export const useIsDocker = () => {
  const { isDocker, setIsDocker } = useContext(IsDockerContext);

  useEffect(() => {
    const storedData = sessionStorage.getItem(SESSION_STORAGE_KEY);

    if (storedData) {
      try {
        const parsedData = JSON.parse(storedData);

        setIsDocker(parsedData);
      } catch (error) {
        console.error('Error parsing session storage data:', error);
      }
    } else {
      setIsDocker(true);
    }
  }, [setIsDocker]);

  return { isDocker, setIsDocker };
};
