/* eslint-disable @typescript-eslint/no-unused-vars */
import { ChangeEvent, FC, MouseEvent, useState } from 'react';

import { useMutation } from '@tanstack/react-query';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';

import { ReactComponent as CheckIcon } from '@/assets/icons/check-green.svg';
import { ReactComponent as CloseIcon } from '@/assets/icons/close-icon.svg';
import {
  Button,
  ButtonVariant,
  Typography,
  TypographyVariant,
} from '@/components';
import api from '@/services/api';
import { emitEvent, EMixPanelEventsMap } from '@/services/mixpanel';

import styles from './styles.module.scss';

export const RenameHost: FC<{ name: string; id: string; isBold?: boolean }> = ({
  name,
  id,
  isBold = false,
}) => {
  const router = useRouter();
  const [value, setValue] = useState<string>(name);
  const [isHostNameLimitRich, setHostNameLimitRich] = useState(false);
  const { data } = useSession();

  const handleSetRename = useMutation({
    mutationFn: () => {
      return api.put(
        `/ex-api/Host/rename?hostId=${id}&hostName=${value}`,
        {},
        {
          headers: {
            Authorization: `Bearer  ${data?.user.accessToken}`,
          },
        }
      );
    },
  });

  if (router.query.renameHost === id) {
    const handleRename = (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      emitEvent(EMixPanelEventsMap.HOST_RENAMED, {
        hostId: id,
        hostName: value,
      });
      handleSetRename.mutate();
      const { renameHost, ...routerQuery } = router.query;
      router.replace({ query: { ...routerQuery } });
    };

    const handleClose = (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      const { renameHost, ...routerQuery } = router.query;
      router.replace({ query: { ...routerQuery } });
    };

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
      e.stopPropagation();

      if (e.target.value.length > 20) {
        setHostNameLimitRich(true);
      } else {
        setValue(e.target.value);
        setHostNameLimitRich(false);
      }
    };

    return (
      <div className={styles.rename}>
        <input
          className={clsx(styles.input, isHostNameLimitRich && styles.error)}
          value={value}
          onChange={handleInputChange}
          onClick={(e) => e.stopPropagation()}
        />
        <Button
          variant={ButtonVariant.Link}
          onClick={handleRename}
          disabled={isHostNameLimitRich}
        >
          <CheckIcon />
        </Button>
        <Button variant={ButtonVariant.Link} onClick={handleClose}>
          <CloseIcon />
        </Button>
      </div>
    );
  }

  return (
    <Typography
      as="h3"
      variant={
        isBold ? TypographyVariant.Heading1Bold : TypographyVariant.Body1Regular
      }
    >
      {name}
    </Typography>
  );
};
