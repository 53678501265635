import { ChangeEvent, startTransition, useEffect, useRef } from 'react';

import { useRouter } from 'next/router';

import { ReactComponent as SearchLogo } from '@/assets/icons/search.svg';
import { debounce } from '@/services/helpers';

import styles from './styles.module.scss';

export const Search = () => {
  const router = useRouter();
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    startTransition(() => {
      router.push(`/store?searchText=${e.target.value}`);
    });
  };

  const handleDebouncedChange = debounce(handleSearch, 500);

  useEffect(() => {
    if (router.pathname !== '/store' && inputRef.current?.value) {
      inputRef.current.value = '';
    }
  }, [router]);

  return (
    <div className={styles.search}>
      <div className={styles.searchWrapper}>
        <input
          ref={inputRef}
          className={styles.searchInput}
          placeholder="Search"
          onChange={handleDebouncedChange}
          defaultValue={router.query.searchText}
        />
        <SearchLogo />
      </div>
    </div>
  );
};
