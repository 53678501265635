import { FC } from 'react';

import { useRouter } from 'next/router';

import {
  EmptyTableState,
  Pagination,
  Select,
  Table,
  Typography,
  TypographyVariant,
} from '@/components';
import { IOption } from '@/components/select/types';
import { emitEvent, EMixPanelEventsMap } from '@/services/mixpanel';

import { HOSTS_PAIRED_COLUMNS, HOSTS_UN_PAIRED_COLUMNS } from './columns';
import styles from './styles.module.scss';
import { IHostsTable } from './types';

const SORT_OPTIONS = [
  { label: 'A - Z', value: 1 },
  { label: 'Z - A', value: 2 },
  { label: 'New - Old', value: 3 },
  { label: 'Old - New', value: 4 },
];

export const HostsTable: FC<IHostsTable> = ({
  hosts,
  isPaired,
  rowClassName,
}) => {
  const router = useRouter();
  const { query } = router;

  if (!hosts?.data.length) {
    return (
      <EmptyTableState
        text={
          !isPaired
            ? 'You don’t have any hosts yet, please click on “Generate” above'
            : 'You don’t have any hosts yet, please add your first host'
        }
      />
    );
  }

  const { pageNumber, totalPages } = hosts || {};

  const handleSetSortBy = (option: IOption) => {
    router.replace({ query: { ...query, sortBy: option.value } });
  };

  const handlePageChange = (page: number) => {
    router.replace({ query: { ...query, pageNumber: page } });
  };

  const handleRowClick = ({ hostId }: { hostId: string }) => {
    emitEvent(EMixPanelEventsMap.VISITED_SINGLE_HOST_PAGE, {
      id: hostId,
    });
    router.push(`/paired-hosts/${hostId}`);
  };

  return (
    <section className={styles.hostsTable}>
      <Typography
        as="h3"
        variant={TypographyVariant.Heading6Bold}
        className={styles.sortLabel}
      >
        Sort
      </Typography>
      <Select
        onChange={(option) => handleSetSortBy(option)}
        className={styles.select}
        options={SORT_OPTIONS}
        selected={SORT_OPTIONS.find(
          (option) => option.value === Number(query?.sortBy)
        )}
        defaultSelected={{ label: 'New - Old', value: 3 }}
      />
      <Table
        rowClassName={rowClassName}
        data={hosts.data}
        columns={isPaired ? HOSTS_PAIRED_COLUMNS : HOSTS_UN_PAIRED_COLUMNS}
        onRowClick={isPaired ? handleRowClick : undefined}
      />
      <Pagination
        current={pageNumber}
        total={totalPages}
        onPageChange={handlePageChange}
        className={styles.pagination}
      />
    </section>
  );
};
