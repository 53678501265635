/* eslint-disable no-console */
import { useContext, useEffect } from 'react';

import { DockerCheckboxesContext } from '@/services/docker-services-checked-list';

const SESSION_STORAGE_KEY = 'dockerCheckedItems';

export const useDockerChecklist = () => {
  const { checkedItems, setCheckedItems } = useContext(DockerCheckboxesContext);

  useEffect(() => {
    const storedData = sessionStorage.getItem(SESSION_STORAGE_KEY);

    if (storedData) {
      try {
        const parsedData = JSON.parse(storedData);
        setCheckedItems(parsedData);
      } catch (error) {
        console.error('Error parsing session storage data:', error);
      }
    }
  }, [setCheckedItems]);

  useEffect(() => {
    if (checkedItems !== undefined) {
      try {
        const dataToStore = JSON.stringify(checkedItems);
        sessionStorage.setItem(SESSION_STORAGE_KEY, dataToStore);
      } catch (error) {
        console.error('Error storing data in session storage:', error);
      }
    }
  }, [checkedItems]);

  return { checkedItems, setCheckedItems };
};
