import { FC } from 'react';

import styles from './styles.module.scss';
import { IBox } from './types';
import { Typography, TypographyVariant } from '../typography';

export const Box: FC<IBox> = ({ title, description }) => {
  return (
    <div className={styles.box}>
      <Typography as="h2" variant={TypographyVariant.Body1Medium}>
        {title}
      </Typography>
      <Typography as="p" className={styles.description}>
        {description}
      </Typography>
    </div>
  );
};
