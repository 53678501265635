import { FC } from 'react';

import { ReactNotifications } from 'react-notifications-component';

import { HostNotifications, ServicesNotifications } from '@/features';

import styles from './styles.module.scss';
import { ILayout } from './types';
import { Menu } from '../menu';

export const MainLayout: FC<ILayout> = ({ children }) => {
  return (
    <section className={styles.layout}>
      <Menu />
      <main className={styles.content}>{children}</main>
      <ReactNotifications className="notification" />
      <ServicesNotifications />
      <HostNotifications />
    </section>
  );
};
