/* eslint-disable no-console */
import { useContext, useEffect } from 'react';

import { ActiveIdContext } from '@/services/docker-active-id';

const SESSION_STORAGE_KEY = 'activeId';

export const useDockerActiveId = () => {
  const { activeId, setActiveId } = useContext(ActiveIdContext);

  useEffect(() => {
    const storedData = sessionStorage.getItem(SESSION_STORAGE_KEY);

    if (storedData) {
      try {
        setActiveId(storedData);
      } catch (error) {
        console.error('Error parsing session storage data:', error);
      }
    }
  }, [setActiveId]);

  useEffect(() => {
    if (activeId !== undefined) {
      try {
        const dataToStore = activeId;
        sessionStorage.setItem(SESSION_STORAGE_KEY, dataToStore);
      } catch (error) {
        console.error('Error storing data in session storage:', error);
      }
    }
  }, [activeId]);

  return { activeId, setActiveId };
};
