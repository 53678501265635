import { FC, useState } from 'react';

import clsx from 'clsx';

import { ReactComponent as RemoveIcon } from '@/assets/icons/remove.svg';

import styles from './styles.module.scss';
import { Button, ButtonVariant } from '../../components/button';
import { ConfirmationModal } from '../../components/confirmation-modal';

export const RemoveActionMenu: FC<{
  id: string;
  name: string;
  displayName?: string;
  onRemove: (id: string) => void;
}> = ({ id, name, displayName, onRemove }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Button
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setIsModalOpen(true);
        }}
        className={clsx(styles.button, displayName && styles.displayName)}
        variant={ButtonVariant.Link}
        {...{ 'aria-label': `remove ${name}` }}
      >
        <RemoveIcon />
        {displayName}
      </Button>
      <ConfirmationModal
        name={name}
        isOpen={isModalOpen}
        onConfirm={() => {
          onRemove(id);
          setIsModalOpen(false);
        }}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
};
