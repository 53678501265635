import { FC } from 'react';

import styles from './styles.module.scss';

interface ILayout {
  children: JSX.Element;
}

export const NotFoundLayout: FC<ILayout> = ({ children }) => {
  return (
    <section className={styles.layout}>
      <main className={styles.content}>{children}</main>
    </section>
  );
};
