import { StatusVariant } from './constants';

export const useStatus = (status: string) => {
  switch (status?.toLowerCase()) {
    case 'active':
      return { text: 'Active', variant: StatusVariant.Active };
    case 'inactive':
      return { text: 'Inactive', variant: StatusVariant.Failed };
    case 'pending':
      return { text: 'Pending', variant: StatusVariant.Pending };
    case 'deactivated':
      return { text: 'Deactivated', variant: StatusVariant.Failed };
    case 'deactivating':
      return { text: 'Deactivating', variant: StatusVariant.Failed };
    case 'activating':
      return { text: 'Activating', variant: StatusVariant.Active };
    case 'freeze':
      return { text: 'Freeze', variant: StatusVariant.Pending };
    case 'not_connected':
      return { text: 'Not Connected', variant: StatusVariant.Failed };
    case 'failed':
      return { text: 'Failed', variant: StatusVariant.Failed };
    case 'unpaired':
      return { text: 'Unpaired', variant: StatusVariant.UnPaired };
    case 'unavailable':
      return { text: 'Unavailable', variant: StatusVariant.UnPaired };
    default:
      return { text: 'Unavailable', variant: StatusVariant.UnPaired };
  }
};
