import { FC, memo } from 'react';

import clsx from 'clsx';

import { ReactComponent as LeftIcon } from '@/assets/icons/arrow-left.svg';
import { ReactComponent as RightIcon } from '@/assets/icons/arrow-right.svg';

import styles from './styles.module.scss';
import { IPagination } from './types';
import { Button, ButtonVariant } from '../button';
import { Typography } from '../typography';

export const Pagination: FC<IPagination> = memo(
  ({ current, total, className, onPageChange }) => {
    if (!total) {
      return null;
    }

    const handlePrev = () => {
      if (current !== 1) {
        onPageChange(current - 1);
      }
    };
    const handleNext = () => {
      if (current !== total) {
        onPageChange(current + 1);
      }
    };

    const handleCurrent = (currentPage: number) => onPageChange(currentPage);

    return (
      <div className={clsx(styles.pagination, className)}>
        <LeftIcon
          className={clsx(styles.arrow, current === 1 && styles.disabled)}
          onClick={handlePrev}
        />
        {generatePagination(total, current, 5).map((page, index) => {
          const active = current === page;

          if (page === 9999999999) {
            return (
              <Typography key={`${page}+${index}`} className={styles.dots}>
                ...
              </Typography>
            );
          }

          return (
            <Button
              variant={active ? ButtonVariant.Primary : ButtonVariant.Link}
              key={page}
              className={clsx(styles.button, active && styles.buttonActive)}
              onClick={() => handleCurrent(page)}
              {...{ 'aria-label': 'page-number' }}
            >
              {page}
            </Button>
          );
        })}
        <RightIcon
          className={clsx(styles.arrow, current === total && styles.disabled)}
          onClick={handleNext}
        />
      </div>
    );
  }
);

Pagination.displayName = 'Pagination';

function generatePagination(
  totalPages: number,
  currentPage: number,
  maxVisiblePages: number
) {
  if (totalPages < 5) {
    const numbersArray = [];

    for (let i = 1; i <= totalPages; i++) {
      numbersArray.push(i);
    }

    return numbersArray;
  }

  const paginationArray = [];

  // Calculate the number of pages before and after the current page
  const pagesBeforeCurrent = Math.floor(maxVisiblePages / 2);
  const pagesAfterCurrent = Math.floor(maxVisiblePages / 2);

  // Determine the start and end pages of the pagination
  let startPage = Math.max(1, currentPage - pagesBeforeCurrent);
  const endPage = Math.min(totalPages, currentPage + pagesAfterCurrent);

  // Ensure the pagination displays the correct number of pages
  if (endPage - startPage + 1 < maxVisiblePages) {
    startPage = Math.max(1, endPage - maxVisiblePages + 1);
  }

  // Add page numbers to the pagination
  for (let i = startPage; i <= endPage; i++) {
    paginationArray.push(i);
  }

  // Add ellipsis before the first page if necessary
  if (startPage > 2) {
    paginationArray.unshift(9999999999);
    paginationArray.unshift(1);
  } else if (startPage === 2) {
    paginationArray.unshift(1);
  }

  // Add ellipsis after the last page if necessary
  if (endPage < totalPages - 1) {
    paginationArray.push(9999999999);
    paginationArray.push(totalPages);
  } else if (endPage === totalPages - 1) {
    paginationArray.push(totalPages);
  }

  return paginationArray;
}
