import { FC, useEffect, useState } from 'react';

import {
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { SessionProvider } from 'next-auth/react';
import MessengerCustomerChat from 'react-messenger-customer-chat';

import { MainLayout } from '@/layouts';
import { ActiveIdContext } from '@/services/docker-active-id';
import { IsDockerContext } from '@/services/docker-context';
import { DockerCheckboxesContext } from '@/services/docker-services-checked-list';
import { emitEvent, EMixPanelEventsMap } from '@/services/mixpanel';
import { IsSsrMobileContext } from '@/services/ssr-mobile-context';
import { UpdateSavedDataContext } from '@/services/update-saved-data';

import '@/styles/reset.scss';
import 'swiper/css';
import 'react-modern-drawer/dist/index.css';
import 'react-notifications-component/dist/theme.css';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';

import { Data, IStoreServices } from './store/features/services/types';

export default function App({
  Component,
  pageProps: { session, ...pageProps },
}: AppProps & { Component: { Layout?: FC } }) {
  const router = useRouter();
  const [queryClient] = useState(() => new QueryClient());
  const [isDocker, setIsDocker] = useState<boolean | null>(null);
  const [updateSavedData, setUpdateSavedData] = useState<number>(0);
  const [checkedItems, setCheckedItems] = useState<
    { [key: string]: Data } | undefined
  >(undefined);
  const [activeId, setActiveId] = useState<string | undefined>(undefined);

  const Layout = Component.Layout ?? MainLayout;

  useEffect(() => {
    emitEvent(EMixPanelEventsMap.SESSION_START);
  }, []);

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1" />
        <title>{process.env.NEXT_PUBLIC_APP_NAME}</title>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <meta
          name="description"
          content="Exitzero automates Linux-based service deployment on virtual machines, cloud servers, and personal hosts. Save time with our powerful tools for scaling and maintenance."
        />
        <meta property="og:title" content={process.env.NEXT_PUBLIC_APP_NAME} />
        <meta
          property="og:description"
          content="Exitzero automates Linux-based service deployment on virtual machines, cloud servers, and personal hosts. Save time with our powerful tools for scaling and maintenance."
        />
        <link rel="sitemap" type="application/xml" href="/sitemap.xml"></link>
        <meta
          name="keywords"
          content="Automated deployment tools, script automation, Linux server management, Scalable service deployment, simultaneous deployment, ease install, ubuntu, installation with bash script, installation pipeline"
        />
      </Head>
      <UpdateSavedDataContext.Provider
        value={{ setUpdateSavedData, updateSavedData }}
      >
        <DockerCheckboxesContext.Provider
          value={{ checkedItems, setCheckedItems }}
        >
          <IsDockerContext.Provider value={{ isDocker, setIsDocker }}>
            <ActiveIdContext.Provider value={{ activeId, setActiveId }}>
              <IsSsrMobileContext.Provider value={pageProps.isMobile}>
                <SessionProvider session={session}>
                  <QueryClientProvider client={queryClient}>
                    <Hydrate state={pageProps.dehydratedState}>
                      <Layout>
                        <Component {...pageProps} />
                      </Layout>
                      <div id="modal-root"></div>
                      {typeof window !== undefined &&
                        process.env.NODE_ENV === 'production' &&
                        !router.pathname.includes('admin') && (
                          <MessengerCustomerChat
                            appId="3059646087675519"
                            pageId="109933248805991"
                            language="en_US" // Optional, set the language
                            loggedInGreeting="Hi there! How can we help you?" // Optional, set the greeting text
                            onCustomerChatDialogShow={() =>
                              emitEvent(EMixPanelEventsMap.OPEN_FB_CHAT)
                            }
                          />
                        )}
                    </Hydrate>
                  </QueryClientProvider>
                </SessionProvider>
              </IsSsrMobileContext.Provider>
            </ActiveIdContext.Provider>
          </IsDockerContext.Provider>
        </DockerCheckboxesContext.Provider>
      </UpdateSavedDataContext.Provider>
    </>
  );
}
