import { createContext } from 'react';

interface DockerContext {
  activeId?: string;
  setActiveId: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export const ActiveIdContext = createContext<DockerContext>({
  activeId: undefined,
  setActiveId: () => null,
});
