import { FC, memo, useEffect, useRef, useState } from 'react';

import clsx from 'clsx';
import Image from 'next/image';
import { Tooltip } from 'react-tooltip';

import { ReactComponent as Cross } from '@/assets/icons/cross.svg';
import { ReactComponent as Docker } from '@/assets/icons/docker.svg';
import { isTextLineClamped } from '@/services/helpers';

import { CardVariant } from './constants';
import styles from './styles.module.scss';
import { ICard } from './types';
import { Button } from '../button';
import { ButtonSize, ButtonVariant } from '../button/constants';
import { Typography, TypographyVariant } from '../typography';

export const Card: FC<ICard> = memo(
  ({
    id,
    variant = CardVariant.Vertical,
    title,
    categoryTitle,
    iconUrl,
    isDocker = true,
    isChecked = false,
    isRemovable = false,
    isActive = false,
    isSaved = false,
    isDateHidden = false,
    isEditorChoice = false,
    className,
    metaDescription,
    onSetTooltip,
    onInstall,
    onCheck,
    onRemove,
    onClick,
  }) => {
    const [isTooltip, setIsTooltip] = useState(false);
    const textRef = useRef<HTMLParagraphElement | null>(null);

    useEffect(() => {
      const { current } = textRef;

      if (current) {
        const isTooltipExist = isTextLineClamped(current);
        setIsTooltip(isTooltipExist);
        onSetTooltip && onSetTooltip(isTooltipExist);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <div
        className={clsx(
          styles.cardWrapper,
          variant && styles[variant],
          onClick && styles.clickable,
          isActive && styles.active,
          isChecked && styles.completed,
          isSaved && styles.completed,
          isDateHidden && styles.dateHidden,
          className
        )}
        onClick={(e) => {
          e.stopPropagation();
          onClick && onClick();
        }}
      >
        <div className={styles.contentWrapper}>
          <div className={styles.imgWrapper}>
            <Image
              priority
              quality={100}
              width={60}
              height={60}
              alt={title}
              src={iconUrl}
            />
          </div>
          <div className={styles.textWrapper}>
            <div className={styles.dockerWrapper}>
              <Typography
                className={styles.title}
                variant={TypographyVariant.Body1Medium}
              >
                {title}
              </Typography>
              {isDocker && <Docker />}
            </div>
            <Typography
              className={styles.categoryTitle}
              variant={TypographyVariant.Body1Regular}
            >
              {categoryTitle}
            </Typography>
          </div>
          {isDocker && onCheck ? (
            <input
              type="checkbox"
              className={styles.checkbox}
              checked={isChecked}
              onClick={(e) => {
                e.stopPropagation();
              }}
              onChange={onCheck}
            />
          ) : isRemovable ? (
            <Button
              className={styles.cross}
              onClick={(e) => {
                e.stopPropagation();
                onRemove && onRemove();
              }}
              variant={ButtonVariant.Link}
            >
              <Cross />
            </Button>
          ) : (
            <Button
              variant={ButtonVariant.Secondary}
              size={ButtonSize.Small}
              className={styles.button}
              onClick={onInstall}
              {...{ 'aria-label': 'install' }}
            >
              Install
            </Button>
          )}
        </div>

        {!isDateHidden && (
          <>
            <p
              ref={textRef}
              data-tooltip-id={`card-meta-${id}`}
              suppressHydrationWarning
              className={styles.metaDescription}
            >
              {metaDescription}
            </p>
            {isTooltip && !isEditorChoice && (
              <Tooltip
                id={`card-meta-${id}`}
                className={styles.tooltip}
                place="bottom-start"
              >
                <Typography className={styles.tooltipWidth} as="p">
                  {metaDescription}
                </Typography>
              </Tooltip>
            )}
          </>
        )}
      </div>
    );
  }
);

Card.displayName = 'Card';
