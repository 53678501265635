import { ElementType } from 'react';

import clsx from 'clsx';

import { DEFAULT_ELEMENT, TypographyVariant } from './constants';
import styles from './styles.module.scss';
import { TypographyProps } from './types';

export const Typography = <T extends ElementType = typeof DEFAULT_ELEMENT>({
  children,
  variant = TypographyVariant.Body1Regular,
  as,
  className,
  ...rest
}: TypographyProps<T>) => {
  const Component = as || DEFAULT_ELEMENT;

  return (
    <Component className={clsx(className, styles[variant])} {...rest}>
      {children}
    </Component>
  );
};
