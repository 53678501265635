import { FC } from 'react';

import clsx from 'clsx';

import styles from './styles.module.scss';
import { IStatus } from './types';
import { useStatus } from './use-status';
import { Typography } from '../typography';

export const Status: FC<IStatus> = ({ status }) => {
  const { variant, text } = useStatus(status);

  return (
    <div className={styles.status}>
      <div className={clsx(styles.dot, styles[variant])} />
      <Typography>{text}</Typography>
    </div>
  );
};
