export enum ButtonVariant {
  Primary = 'primary',
  Secondary = 'secondary',
  Link = 'link',
}

export enum ButtonSize {
  Small = 'small',
  Large = 'large',
}
