import { useIsMobile } from '@/hooks';

import { Header } from '../header';
import { LeftSide } from '../left-side';

export const Menu = () => {
  const isMobile = useIsMobile();

  return isMobile ? <Header /> : <LeftSide />;
};

Menu.displayName = 'Menu';
