import { createContext } from 'react';

interface DockerContext {
  isDocker: boolean | null;
  setIsDocker: React.Dispatch<React.SetStateAction<boolean | null>>;
}

export const IsDockerContext = createContext<DockerContext>({
  isDocker: true,
  setIsDocker: () => null,
});
