import { useContext } from 'react';

import { useMediaQuery } from '@/hooks';
import { IsSsrMobileContext } from '@/services/ssr-mobile-context';

export const useIsMobile = () => {
  const isSsrMobile = useContext(IsSsrMobileContext);
  const isBrowserMobile = useMediaQuery('(max-width: 540px)');

  return isSsrMobile || isBrowserMobile;
};
